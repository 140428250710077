import React from "react";

export const About = () => {
  return (
    <div className="about-container">
      <div className="about-bio">
        {/* <img className="border-image" src="/assets/photo/about_border-2.jpg" /> */}
      <div className="co-description"><i>~ A creative house based in New York & Los Angeles ~</i></div>
        {/* <div><i>Based in New York & Los Angeles</i></div> */}
        {/* <div>
        Let's Get Dinner Sometime is a full-service creative house based in New York and Los Angeles, specializing in high-quality motion and still production for fashion campaigns, commercials, music videos, and more
        </div> */}
        {/* <img className="border-image" src="/assets/photo/about_border-1.jpg" /> */}
      </div>
    </div>
  );
};

import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import video from "../../../video/vogue_italia.mov";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
    };

    handleResize(); // Check on mount
    window.addEventListener("resize", handleResize); // Check on resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  return isMobile;
};

export const VogueItalia = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useIsMobile();

  const handlePlay = () => {
    videoRef.current.play().catch((error) => {
      console.error("Error playing video: ", error);
    });
  };

  const handlePause = () => {
    videoRef.current.pause();
  };

  const goFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        // Firefox
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        // IE/Edge
        videoRef.current.msRequestFullscreen();
      }
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  return (
    <div className="video-page">
      <div className="video-title">Condé Nast | Vogue Italia</div>
      <div className="tv-container">
        <img
          className="tv-image"
          src="/assets/photo/tv-3.jpg"
          alt="drawing of TV"
        ></img>
        {isMobile ? (
          <video
            ref={videoRef}
            className="tv-video"
            loop
            playsInline
            controls
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video
            ref={videoRef}
            className="tv-video"
            width={isFullscreen && isMobile ? "100%" : "600"}
            loop
            playsInline
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="controls">
        <button onClick={handlePlay}>Play</button>
        <button onClick={handlePause}>Pause</button>
        {isMobile ? <></> : <button onClick={goFullScreen}>Expand</button>}
        <button onClick={toggleMute}>{isMuted ? "Unmute" : "Mute"}</button>
      </div>
      <br />
      <br />
      <Link to="/" className="back-btn">
        back to menu
      </Link>
    </div>
  );
};

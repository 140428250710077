import React from "react";

export const Landing = () => {
  return (
    <div className="landing-container">
      <img className="logo" src="/assets/photo/lgdsheader.jpg" alt="logo" />
      <div id="landing-header" className="header">let's get dinner sometime</div>
    </div>
  );
};

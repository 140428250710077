import React from "react";
import "./styles/App.scss";
import { Switch, Route } from "react-router-dom";
import { Landing } from "./components/Landing";
import { About } from "./components/About/About";
import { Clients } from "./components/Clients/Clients";
import { Contact } from "./components/Contact/Contact";
import { InProgressPage } from "./components/shared/InProgressPage";
import { VogueItalia } from "./components/Clients/SelectedClients/VogueItalia";

function App() {
  return (
    <div className="App">
      <div className="box">
        <div className="box-inner">
          <Switch>
            <Route exact path="/">
              <Landing />
              <About />
              <Clients />
              <Contact />
            </Route>
            <Route exact path="/in-progress">
              <InProgressPage />
            </Route>
            <Route exact path="/vogue-italia">
              <VogueItalia />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
